<template>
  <!-- 分销管理 - 分销商城 - 分销商品 -->
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <div title="商品名称" class="searchboxItem">
          <span class="itemLabel">商品名称:</span>
          <el-input
            v-model="retrievalData.tradeName"
            type="text"
            size="small"
            placeholder="请输入商品名称"
            clearable
          />
        </div>
        <!-- <div title="商品分类" class="searchboxItem">
          <span class="itemLabel">商品分类:</span>
          <el-cascader
            :options="tradeTypeData"
            v-model="retrievalData.tradeType"
            :props="miniProps"
            size="small"
            clearable
          ></el-cascader>
        </div> -->
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      size="small"
      tooltip-effect="dark"
      style="width: 100%"
      :header-cell-style="tableHeader"
      stripe
      @sort-change="changeSort"
      :default-sort="{ prop: 'auditTime', order: 'ascending' }"
    >
      <el-table-column
        label="序号"
        align="center"
        type="index"
        :index="indexMethod"
        width="100px"
      />
      <el-table-column
        label="订单编号"
        align="left"
        show-overflow-tooltip
        prop="orderSn"
        width="200px"
      />
      <el-table-column
        label="商品名称"
        align="left"
        show-overflow-tooltip
        prop="productName"
        width="200px"
      />
      <el-table-column
        label="商品单价(元)"
        align="right"
        show-overflow-tooltip
        prop="productPrice"
        width="140px"
      />
      <el-table-column
        label="购买数量"
        align="right"
        show-overflow-tooltip
        prop="productCnt"
        width="100px"
      />
      <el-table-column
        label="实付金额（元）"
        align="right"
        show-overflow-tooltip
        prop="paymentMoney"
        width="120px"
      />
      <el-table-column
        label="下单时间"
        align="left"
        show-overflow-tooltip
        prop="orderTime"
        width="140px"
      />
      <el-table-column
        label="已绑定用户数"
        align="right"
        show-overflow-tooltip
        prop="bindUserNum"
        width="100px"
      />
      <el-table-column
        label="已兑换卡密数"
        align="right"
        show-overflow-tooltip
        prop="useCdKeyNum"
        width="100px"
      />
      <el-table-column
        label="剩余兑换数量"
        align="right"
        show-overflow-tooltip
        prop="surplusNum"
        width="100px"
      />
      <el-table-column
        label="操作"
        align="center"
        show-overflow-tooltip
        width="200px"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            style="padding: 0 5px"
            type="text"
            size="mini"
            @click="bdPhone(scope.row)"
            >查看绑定用户</el-button
          >
          <el-button
            style="padding: 0 5px"
            type="text"
            size="mini"
            @click="viewCarmi(scope.row)"
            >查看卡密</el-button
          >
          <!-- <el-button
            style="padding: 0 5px"
            type="text"
            size="mini"
            @click="buyAgain(scope.row)"
            >再次购买</el-button
          > -->
        </template>
      </el-table-column>
      <Empty slot="empty" />
    </el-table>
    <PageNum
      style="position: absolute; bottom: 0; right: 0"
      :apiData="apiData"
      @sizeChange="sizeChange"
      @getData="getData"
    />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "CoursewareToBereviewed",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      // 检索数据
      retrievalData: {
        tradeName: "", // 商品名称
        tradeType: "", // 商品分类
      },
      // 商品分类下拉数据
      tradeTypeData: [],
      // 商品分类下拉数据 - 对应数据类型
      miniProps: {
        label: "name",
        value: "courseTypeId",
        checkStrictly: false,
        emitPath: false,
      },
    };
  },
  computed: {},
  methods: {
    // 获取课程分类数组数据
    getcourseTypeArrData() {
      this.$post(
        "miniapp/coursetype/selectTree",
        { hiddenState: "10" },
        3000,
        true,
        2
      ).then((ret) => {
        this.tradeTypeData = this.delchild(ret.data);
      });
    },
    // 处理-获取课程分类数组数据
    delchild(list = []) {
      for (let i = 0; i < list.length; i++) {
        const el = list[i];
        if (el.children.length == 0) {
          delete el.children;
        }
        this.delchild(el.children);
      }
      return list;
    },
    // 获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        productName: this.retrievalData.tradeName,
        courseType: this.retrievalData.tradeType,
      };
      this.doFetch(
        {
          url: "/comp/drp-product-comp/queryMiniappOrderList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 绑定用户 - 弹框
    bdPhone(row) {
      this.$router.push({
        path: "/web/jgDistribution/myDistribution_1_detail",
        query: {
          orderId: row.orderId,
          courseId: row.productId,
          productCompanyId: row.productCompanyId,
        },
      });
    },
    // 查看卡密
    viewCarmi(row) {
      this.$router.push({
        path: "/web/jgDistribution/carmichaelInfo",
        query: {
          orderId: row.orderId,
          courseId: row.productId,
        },
      });
    },
    // 再次购买
    buyAgain(row) {
      this.$confirm("您确定要移除该商品么, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post(
            "/course/distribution/delete",
            { courseDistribution: row.courseDistribution },
            3000,
            true,
            2
          ).then((res) => {
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  watch: {
    name: {
      handler: function (val, oldVal) {
        if (val == "first") {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    this.getcourseTypeArrData();
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table__empty-block {
  min-height: 500px;
}
</style>