<template>
  <!-- 机构端 - 分销管理 - 我的分销 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">分销管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">我的分销</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="summary">
          <div>
            <p>购买总数</p>
            <p class="p2">{{ topThreeData.buyTotalNum }}</p>
          </div>
          <div>
            <p>已兑换数量</p>
            <p class="p2">{{ topThreeData.exchangeNum }}</p>
          </div>
          <div>
            <p>支出金额（元）</p>
            <p class="p2">{{ topThreeData.payAmount }}</p>
          </div>
        </div>
        <el-tabs
          v-model="activeName"
          :before-leave="beforeTabLeave"
          @tab-click="tabsChange"
          ><el-tab-pane label="我的订单" name="first">
            <myDistribution1
              v-on:childByValue="childByValue"
              :name="activeName"
              ref="first"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import myDistribution1 from "@/views/jgDistribution/myDistribution_1.vue";
export default {
  name: "myDistribution",
  components: {
    myDistribution1,
  },
  data() {
    return {
      activeName: "first",
      // 最上方三个统计
      topThreeData: {},
    };
  },
  computed: {},
  methods: {
    // tap选项卡的切换
    tabsChange(e) {
      this.activeName = e.name;
    },
    // 最上方三个统计
    getcourseTypeArrData() {
      this.$post(
        "/comp/drp-product-comp/queryMiniappOrderStatistic",
        {},
        3000,
        true,
        2
      ).then((res) => {
        this.topThreeData = res.data;
      });
    },
    // 子组件绑定用户后 - 刷新最上方三个统计
    childByValue() {
      this.getcourseTypeArrData();
    },
  },
  
  created() {
    this.getcourseTypeArrData();
  },
};
</script>
<style lang="less">
.summary {
  width: 100%;
  height: 60px;
  div {
    width: 32%;
    float: left;
    p {
      text-align: center;
      line-height: 30px;
    }
    .p2 {
      font-size: 18px;
      font-weight: 600;
      color: #409eff;
    }
  }
}
</style>
